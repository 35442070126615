<template>
    <div>
        <InputMrnModal v-if="currentModal === 'MODAL_INFO' || currentModal === 'MODAL_DETAIL'"/>
        <InfoPatientModal v-if="currentModal === 'MODAL_DETAIL'"/>
        <ConfirmModal v-if="currentModal === 'MODAL_CONFIRMATION'"/>
    </div>
</template>

<script>
import InputMrnModal from "./_components/InputMrnModal.vue";
import InfoPatientModal from "./_components/InfoPatientModal.vue";
import ConfirmModal from "./_components/ConfirmModal.vue";
import { computed } from "vue";
// import testCases from '../../services/inputMrn.json'
import { machineModalMrReg } from "./machine/InputMrn";
import { useInterpret } from "xstate-vue2";
// import TestCase from './machine/caseTest'
// import TestCase from './machine/withShowCase'

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BlockUI from "@/components/_js/BlockUI";
import SweetAlertTemplate from "@/components/_general/SweetAlert";
const PatientRepository = RepositoryFactory.get("patient");
const ReservationRepository = RepositoryFactory.get("reservation");
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    name: "indexInputMrn",
    components: {
        InputMrnModal,
        InfoPatientModal,
        ConfirmModal,
    },
    props: {
        modalData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        const machine = machineModalMrReg.withConfig({
            services: {
                getPatientId: (context) => {
                    return new Promise((resolve, reject) => {
                        PatientRepository.getPatientNew(context.Patient.PasienId)
                            .then(_resp => {
                                resolve({Mrn: _resp.data.medicalNo})
                            }).catch(() => {
                                reject(false)
                        })
                    })
                },
                getInfoDetailMRN: (context) => {
                    return new Promise((resolve, reject) => {
                        PatientRepository.searchMrnByPatien(context.Mrn)
                            .then((_resp) => {
                                const data = _resp.data;
                                const itemResult = {
                                    Nama: data.patientName ? data.patientName.trim() : data.patientName,
                                    TanggalLahir: data.dateOfBirth,
                                    Gender: data.jenisKelamin.initial,
                                    NoTelp: data.mobilePhone1,
                                    Email: data.email,
                                    Mrn: data.medicalNo,
                                };
                                resolve(itemResult);
                            })
                            .catch((err) => {
                                if (err.response.status == 404) {
                                    resolve({});
                                } else {
                                    reject(new Error(err));
                                }
                            });
                    });
                },
                saveMRN: (context) => {
                    return new Promise((resolve, reject) => {
                        this.confirmationSave()
                        .then(() => {
                            blockUI.blockModal();
                            var payload = {
                                generatedId: context.Patient.id,
                                mrn: context.Mrn ? context.Mrn : context.Patient.Mrn,
                                nomorRegistrasi: context.NoRegis,
                            };
                            ReservationRepository.updateArriveAppointment(
                                payload
                            )
                                .then(() => {
                                    new SweetAlert().showInfo({
                                        swalTitle: "Berhasil!",
                                        swalText:
                                            "Perubahan data / status berhasil disimpan.",
                                        swalType: "success",
                                        onConfirmButton: function () {
                                            blockUI.unblockModal();
                                            resolve(true);
                                        },
                                    });
                                })
                                .catch(() => {
                                    new SweetAlert().showInfo({
                                        swalTitle: "Gagal!",
                                        swalText: "Terjadi Kesalahan",
                                        swalType: "error",
                                        onConfirmButton: function () {
                                            reject("error");
                                            blockUI.unblockModal();
                                        },
                                    });
                                });
                        }).catch(() => {
                            reject("error");
                        });
                    });
                },
                showErrorAlert: () => {
                    return new Promise((resolve) => {
                        new SweetAlert().showInfo({
                            swalTitle: "Gagal",
                            swalText:
                                "Gagal menampilkan Data",
                            swalType: "error",
                            onConfirmButton: function () {
                                resolve(true)
                            },
                        });
                    })
                }
            },
            actions: {
                closeModal: () => {
                    this.$store.commit(
                        "DayCalendarStore/SET_SHOW_MODAL_MRREG",
                        false
                    );
                }
            },
        });
        return {
            machine,
            formData: {},
            service: null,
            abortController: null,
        };
    },
    computed: {
        currentModal() {
            return this.formData && this.formData.PageModal
                ? this.formData.PageModal
                : "";
        },
    },
    provide() {
        return {
            formData: computed(() => this.formData),
            openInfoPatientModal: () => {
                this.service.send({
                    type: "open:detail",
                });
            },
            buttonClose: () => {
                this.service.send({
                    type: "close:modal",
                });
            },
            closeDetail: () => {
                this.service.send({
                    type: "tutup",
                });
            },
            setMrn: (val) => {
                this.service.send({
                    type: "input:mrn",
                    data: val,
                });
            },
            clearMrn: () => {
                this.service.send({
                    type: 'clear:mrn'
                })
            },
            setNama: (val) => {
                this.service.send({
                    type: "input:pasien",
                    data: val,
                });
            },
            clearNama: () => {
                this.service.send({
                    type: "input:pasien",
                    data: '',
                });
            },
            setTanggalLahir: (val) => {
                this.service.send({
                    type: "input:dob",
                    data: val,
                });
            },
            setNoRegis: (val) => {
                this.service.send({
                    type: "input:noReg",
                    data: val,
                });
            },
            clearNoRegis: () => {
                this.service.send({
                    type: "clear:noReg"
                });
            },
            refreshButton: () => {
                this.service.send({
                    type: "refresh"
                });
            },
            buttonEdit: () => {
                this.service.send({
                    type: "editMRN",
                });
            },
            buttonBatal: () => {
                this.service.send({
                    type: "batal",
                });
            },
            buttonSave: () => {
                this.service.send({
                    type: "lanjutkan",
                });
            },
            saveConfirmation: () => {
                this.service.send({
                    type: "lanjutkan",
                });
            },
            closeConfirmation: () => {
                this.service.send({
                    type: "kembali",
                });
            },
        };
    },
    mounted() {
        const initStatate = "";
        const initContext = this.mapperDataPasien(this.modalData);
        this.service = useInterpret(
            this.machine,
            {
                devTools: true,
                state: this.machine.transition(
                    initStatate,
                    { type: "" },
                    initContext
                ),
            },
            (state) => {
                this.formData = state.context;
            }
        );
    },
    methods: {
        getMrnByPasienId (pasienId) {
            return new Promise((resolve, reject) => {
                PatientRepository.getPatientNew(pasienId)
                    .then(({ data }) => {
                       resolve(data)
                    })
                    .catch(error => {
                       reject(error)
                    })
            })
        },
        mapperDataPasien(item) {
            return {
                PageModal: 'MODAL_INFO',
                Patient: {
                    id: item.generatedId,
                    Nama: item.namaPasien,
                    TanggalLahir: item.tanggalLahir,
                    Gender: item.gender,
                    NoTelp: item.telp,
                    Email: item.email,
                    ...(item.patiendId ? {PasienId: item.patiendId} : {}),
                    ...(item.data.nomorMedicalRecord
                        ? { Mrn: item.data.nomorMedicalRecord }
                        : {}),
                },
            };
        },
        confirmationSave() {
            return new Promise((resolve, reject) => {
                new SweetAlert().showConfirm({
                    swalTitle: "Apakah Anda yakin?",
                    swalText: "Perubahan Data atau Status akan tersimpan.",
                    swalType: "info",
                    onConfirmButton: function () {
                        resolve(true);
                    },
                    onCancelButton: function () {
                        reject(false);
                    },
                });
            });
        },
    },
};
</script>
<style scoped>
.position-center-text {
    position: absolute;
    top: 0;
    left: 40%;
    background: white;
    z-index: 99999;
}
</style>
