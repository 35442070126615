
<template>

    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 20 20" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M17.6391 2.36035L2.36133 17.6381" 
            :stroke="strokeColor"
            stroke-width="1.66667" 
            stroke-linecap="round"
            stroke-linejoin="round" 
        />
        <path 
            d="M2.36133 2.36035L17.6391 17.6381" 
            :stroke="strokeColor" 
            stroke-width="1.66667" 
            stroke-linecap="round"
            stroke-linejoin="round" 
        />
    </svg>

</template>

<script>

export default {
    props: {
        width : {
        type : Number,
        default: 18
        },
        height : {
            type: Number,
            default: 18
        },
        strokeColor : {
            type: String,
            default: "#929292"
        }
    }
}

</script>