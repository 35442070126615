<template>
    <div
        class="rounded h-100 pointer slot-jadwal with-shadow"
        @click="eventClick"
        :id="id"
        :class="[classBackground, { active: isActive }]"
        :style="{ borderLeft: `5px solid ${data.hexColor}`}"
    >   
        <template v-if="data.type === SlotJadwalType.HOLIDAY">
            <div>
                <label class="position-slot-holiday">Libur <br> {{data.holidayRemarks}} </label>
                
            </div>
        </template>
        <template v-if="data.type == SlotJadwalType.WAITING">
            <div class="text-left p-2 waiting-list" v-if="jumlahWaiting > 0">
                <!-- <label class="dots-waiting m-auto" :style="{backgroundColor: data.hexColor}"></label> -->
                ( {{data.data[0].jadwal.jamMulai}} - {{data.data[0].jadwal.jamSelesai}} )  Terdapat <strong>{{ jumlahWaiting }}</strong> waiting list
            </div>
        </template>
        <template v-else>
            <div  v-if="data.type != SlotJadwalType.HOLIDAY" class=" box-content-slot-jadwal" :class="data.type == SlotJadwalType.RESERVED ? 'grid-slot-3': 'grid-slot-2'">
                <div class="text-left">
                    {{ start | formatTime }} - {{ end | formatTime }}
                    <span v-if="data.reservasi && data.reservasi.jamMulaiAppointment && data.reservasi.jamMulai != data.reservasi.jamMulaiAppointment">
                        (appt. {{ data.reservasi.jamMulaiAppointment }} - {{ data.reservasi.jamSelesaiAppointment }})
                    </span>
                    <div
                        v-if="
                            data.type === SlotJadwalType.ANTRIAN
                        "
                    >
                       WALK-IN
                    </div>
                    <div
                        v-else-if="
                            data.type === SlotJadwalType.LUNCHBREAK ||
                            data.type === SlotJadwalType.OTHER_EVENT ||
                            data.type === SlotJadwalType.CLOSE_REGISTRATION ||
                            data.type === SlotJadwalType.IN_OPERATION
                        "
                    >
                        {{ data.type }}
                    </div>
                    <div
                        v-else-if="
                            data.type !== SlotJadwalType.EMPTY &&
                                data.type !== SlotJadwalType.TELAT &&
                                data.type !== SlotJadwalType.INTERNAL_EVENT &&
                                data.type !== SlotJadwalType.BLOCKED
                        "
                    >
                        {{ data.type }} by {{ data.reservasi ? data.reservasi.namaPasien : '-' }}
                    </div>
                    <div
                        v-else-if="
                            data.type !== SlotJadwalType.EMPTY &&
                                data.type !== SlotJadwalType.RESERVED
                        "
                    >
                        {{ data.type }}
                        <p v-if="data.reservasi && data.reservasi.remark">
                            {{ data.reservasi.remark }}
                        </p>
                    </div>
                    <div v-if="data.reservasi && data.reservasi.pesan">
                        {{ data.reservasi.pesan }}
                    </div>
                </div>
                <!--  -->
                <template
                    v-if="
                        xVue.state.DoctorAppointmentStore.isPermissionWhatsapp
                    "
                >
                    <div
                        class="text-center"
                        v-if="data.reservasi && data.reservasi.telp"
                    >
                        Telp:
                        <span
                            class="show-bigger"
                            @click.stop="WAme(data.reservasi.telp, data.reservasi.generatedId)"
                            >{{ data.reservasi.telp }} </span
                        >
                    </div>
                </template>
                <template v-else>
                    <div class="text-center" v-if="data.reservasi && data.reservasi.telp">
                        Telp: <span>{{ data.reservasi.telp }}</span>
                    </div>
                </template>

                <!--  -->
                <div
                    class="text-right"
                    v-if="data.type == SlotJadwalType.EMPTY"
                >
                    <i class="fa fa-plus pointer"></i>
                    <!-- <i class="fas fa-headset pointer"></i> -->
                </div>
                <div
                    class="text-right"
                    v-else-if="data.type == SlotJadwalType.BLOCKED"
                >
                    <i class="fas fa-ban"></i>
                </div>

                <div
                    class="text-right"
                    v-else-if="
                        data.reservasi && data.reservasi.createdVia == 'PUBLIC'
                    "
                >
                    <i class="fas fa-mobile-alt"></i>
                </div>
                <div
                    class="text-right"
                    v-else-if="data.reservasi && data.reservasi.telekonsul"
                >
                    <i class="fas fa-headset pointer"></i>
                </div>
            </div>
        </template>
        <b-tooltip
            :target="`slot-${data.reservasi.id}`"
            triggers="hover"
            v-if="
                data.reservasi &&
                    data.reservasi.telp &&
                    xVue.state.DoctorAppointmentStore.isPermissionWhatsapp
            "
        >
            <div class="row text-left">
                <div class="col-12">
                    {{ start | formatTime }} - {{ end | formatTime }}
                </div>
                <div
                    class="col-12"
                    v-if="
                        data.type !== SlotJadwalType.EMPTY &&
                            data.type !== SlotJadwalType.TELAT &&
                            data.type !== SlotJadwalType.INTERNAL_EVENT &&
                            data.type !== SlotJadwalType.BLOCKED
                    "
                >
                    {{ data.type }} by {{ data.reservasi ? data.reservasi.namaPasien : '-' }}
                </div>
                <div
                    class="col-12"
                    v-else-if="
                        data.type !== SlotJadwalType.EMPTY &&
                            data.type !== SlotJadwalType.RESERVED
                    "
                >
                    {{ data.type }}
                    <p v-if="data.reservasi && data.reservasi.remark">
                        {{ data.reservasi.remark }}
                    </p>
                </div>
                <div
                    class="d-block text-left col-12 my-2"
                    v-if="data.reservasi && data.reservasi.pesan"
                >
                    {{ data.reservasi.pesan }}
                </div>
                <div
                    class="d-block pointer col-12"
                    v-if="data.reservasi && data.reservasi.telp"
                >
                    <span
                        class="show-bigger"
                        @click.stop="WAme(data.reservasi.telp, data.reservasi.generatedId)"
                        >{{ data.reservasi.telp }} </span
                    >
                </div>
            </div>
        </b-tooltip>
    </div>
</template>

<script>
import SlotJadwalType from "./../../../../constants/slot-jadwal-type-enum";
import ReservasiStatus from "./../../../../constants/reservasi-status-enum";
import ColorSlotJadwal from "./../../../../constants/color-slot-jadwal-enum";
export default {
    props: {
        xVue: {
            type: Object
        },
        data: {
            type: Object
        },
        jumlahWaiting: {
            type: Number
        },
        start: {
            type: String
        },
        end: {
            type: String
        },
        hexColor: {
            type: String
        }
    },
    data() {
        return {
            SlotJadwalType: SlotJadwalType,
            isActive: false,
            id: this.data.reservasi && this.data.reservasi.id
                ? `slot-${this.data.reservasi.id}`
                : this._uid
        };
    },
    filters: {
        formatTime: function(value) {
            return moment(value).format("HH:mm");
        }
    },
    computed: {
        classBackground() {
            if(this.data.type == SlotJadwalType.HOLIDAY || this.data.type == SlotJadwalType.IN_OPERATION || this.data.type == SlotJadwalType.LUNCHBREAK) {
                return ColorSlotJadwal.HOLIDAY
            } else if (this.data.type == SlotJadwalType.RESERVED) {
                if (this.data.reservasi && this.data.reservasi.status == ReservasiStatus.CONFIRM) {
                    return ColorSlotJadwal.NOT_ARRIVED;
                } else {
                    return ColorSlotJadwal.RESERVED;
                }
            } else if ([SlotJadwalType.BLOCKED,  SlotJadwalType.OTHER_EVENT, SlotJadwalType.ANTRIAN, SlotJadwalType.CLOSE_REGISTRATION].some(_item => _item == this.data.type)) {
                return ColorSlotJadwal.BLOCKED;
            } else if (this.data.type == SlotJadwalType.DEFECT) {
                return ColorSlotJadwal.DEFECT;
            } else if (this.data.type == SlotJadwalType.INTERNAL_EVENT) {
                return ColorSlotJadwal.INTERNAL_EVENT;
            } else if (this.data.type == SlotJadwalType.TELAT) {
                return ColorSlotJadwal.TELAT;
            } else {
                return ColorSlotJadwal.EMPTY;
            }
        },
        events() {
            return this.xVue.state.DayCalendarStore.selectedEvent;
        }
    },
    watch: {
        events(val) {
            this.isActive = false;
            if (this.data && val) {
                // handle event null
                if (this.data.reservasi && val.reservasi) {
                    if (val.reservasi.id == this.data.reservasi.id) {
                        this.isActive = true;
                    } else {
                        this.isActive = false;
                    }
                } else {
                    if (val.start == this.start 
                        && val.end == this.end
                        && val.hexColor == this.hexColor &&
                        val.type == this.data.type
                        ) {
                        this.isActive = true;
                    } else {
                        this.isActive = false;
                    }
                }
            }
        }
    },
    methods: {
        eventClick() {
            // console.log(this.data)
           if(this.data.type == SlotJadwalType.HOLIDAY) return
           if([SlotJadwalType.IN_OPERATION, SlotJadwalType.LUNCHBREAK].some(_item => _item === this.data.type)) {
            // 
           }
            if (this.data.type == SlotJadwalType.WAITING) {
                this.xVue.commit(
                    "DayCalendarStore/SET_SHOW_MODAL_WAITING",
                    true
                );
                this.xVue.dispatch('DayCalendarStore/SHOW_LIST_WAITING',
                    this.data.data
                )
            } else if (SlotJadwalType.APPOINTMENT == this.data.type || SlotJadwalType.RESERVED == this.data.type ){
                this.xVue.commit("DayCalendarStore/SET_SELECTED_EVENT", this.data);
                this.xVue.dispatch('DayCalendarStore/GET_INFO_PANEL', this.data.reservasi.generatedId)
            } else if (SlotJadwalType.DEFECT == this.data.type) {
                var object = { ...this.data };
                object.start = this.start;
                object.end = this.end;
                object.generatedId = this.data.reservasi.generatedId
                this.xVue.dispatch('DayCalendarStore/GET_INFO_PANEL_EMPTY', object)
                this.xVue.commit("DayCalendarStore/SET_SELECTED_EVENT", object);
            }else {
                var object = { ...this.data };
                object.start = this.start;
                object.end = this.end;
                this.xVue.dispatch('DayCalendarStore/GET_INFO_PANEL_EMPTY', object)
                this.xVue.commit("DayCalendarStore/SET_SELECTED_EVENT", object);
            }
            // var object = { ...this.data };
            // this.xVue.commit("DayCalendarStore/SET_SELECTED_EVENT", object);
        },
        WAme(numberWA,idReservasi) {
            this.xVue.commit("DayCalendarStore/SET_SHOW_MODAL_WA", true);
            this.xVue.commit("DayCalendarStore/SET_NUMBER_WA", numberWA);
            this.xVue.commit("DayCalendarStore/SET_RESERVASI_ID", idReservasi);
            // const url = `https://wa.me/${numberWA}`
            // window.open(`${url}?text=Nomor%20Anda%20Dipanggil`, "_blank");
        }
    },
    mounted() {
        setTimeout(()=> {
            const getMargin = $(`#${this.id}`)
            if (getMargin.parent().css('marginRight') != '0px') {
                getMargin.css('width', '50%')
            }
        }, 10)
    }
};
</script>

<style lang="scss" scoped></style>
