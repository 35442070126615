<template>
    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 20 20"
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_301_273)">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.001 3.75C11.0361 3.75 11.876 2.91055 11.876 1.875C11.876 0.839453 11.0361 0 10.001 0C8.96582 0 8.12598 0.839453 8.12598 1.875C8.12598 2.91055 8.96582 3.75 10.001 3.75ZM2.92988 5.58203C3.6623 6.3125 4.84863 6.3125 5.58301 5.58203C6.31348 4.84766 6.31348 3.66133 5.58301 2.92891C4.84863 2.19648 3.6623 2.19648 2.92988 2.92891C2.19785 3.66133 2.19785 4.84766 2.92988 5.58203Z"
                :fill="strokeColor" 
            />
            <path
                d="M0.000976563 10C0.000976563 8.96484 0.84043 8.125 1.87598 8.125C2.91152 8.125 3.75098 8.96484 3.75098 10C3.75098 11.0352 2.91152 11.875 1.87598 11.875C0.84043 11.875 0.000976563 11.0352 0.000976563 10Z"
                :fill="strokeColor" 
            />
            <path
                d="M2.92988 17.0703C2.19746 16.3398 2.19746 15.1523 2.92988 14.418C3.6623 13.6875 4.84863 13.6875 5.58301 14.418C6.31348 15.1523 6.31348 16.3398 5.58301 17.0703C4.84863 17.8047 3.6623 17.8047 2.92988 17.0703Z"
                :fill="strokeColor" 
            />
            <path
                d="M11.876 18.125C11.876 19.1602 11.0361 20 10.001 20C8.96582 20 8.12598 19.1602 8.12598 18.125C8.12598 17.0898 8.96582 16.25 10.001 16.25C11.0361 16.25 11.876 17.0898 11.876 18.125Z"
                :fill="strokeColor" 
            />
            <path
                d="M14.4189 14.418C15.1533 13.6875 16.3408 13.6875 17.0713 14.418C17.8057 15.1523 17.8057 16.3398 17.0713 17.0703C16.3408 17.8047 15.1533 17.8047 14.4189 17.0703C13.6885 16.3398 13.6885 15.1523 14.4189 14.418Z"
                :fill="strokeColor" 
            />
            <path
                d="M20.001 10C20.001 11.0352 19.1611 11.875 18.126 11.875C17.0908 11.875 16.251 11.0352 16.251 10C16.251 8.96484 17.0908 8.125 18.126 8.125C19.1611 8.125 20.001 8.96484 20.001 10Z"
                :fill="strokeColor" 
            />
        </g>
        <defs>
            <clipPath id="clip0_301_273">
                <rect 
                    width="20" 
                    height="20" 
                    fill="white" 
                    transform="translate(20) rotate(90)" 
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script >

export default {
    props: {
        width : {
            type : Number,
            default: 20
        },
        height : {
            type: Number,
            default: 20
        },
        strokeColor : {
            type: String,
            default: "#929292"
        }
    }
}

</script>