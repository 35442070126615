<template>
    <div>
      <div class="modal show" tabindex="-1" style="display: block">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content px-2" style="border-radius: 12px; height: 644px;">
            <div class="modal-header" style="margin-bottom: 2rem;">
              <p class="modal-title" style="padding-left: 10px; font-weight: 600; font-size: 20px; color: #636363;">Informasi Tambahan</p>
              <button @click="buttonClose" type="button" aria-label="Close" style="border: none; background-color: transparent;">
                <CloseIcon :width="22" :height="22"/>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3 row align-items-start">
                  <!-- Input Field for No Rekam Medik Existing -->
                  <label for="rekamMedik" class="col-sm-3 form-label font-weight-bold" style="margin-top: 1rem;  color: #636363; font-size: 16px;">No. Rekam Medik<span class="text-danger">*</span></label>
                  <div class="col-7">
                    <div v-if="formData.Form === 'EDIT'" class="d-flex align-items-center" style="margin-bottom: 1rem">
                      <div class="input-wrapper">
                        <input 
                          type="text"
                          id="rekamMedik" 
                          class="form-control" 
                          disabled 
                          style="cursor: not-allowed;"
                          :value="formData.Patient.Mrn"
                        >       
                      </div>
                      <button v-if="formData.MrnStatus ==='INVISIBLE'" @click="buttonEdit" type="button" class="btn font-weight-bold" style="color: #5578EB; border-radius: 10px; font-size: 14px;">Edit</button>
                    </div>
                    <!-- Input Field for No Rekam Medik  -->
                    <div v-if="(formData.Form === 'INPUT' || formData.Form === 'EDIT') && formData.MrnStatus !== 'INVISIBLE'" class="d-flex align-items-center">
                      <div class="input-wrapper">
                        <input 
                          type="text" 
                          id="rekamMedik" 
                          class="form-control"
                          :class="{
                            'input-error': formData.MrnStatus === 'ERROR', 
                            'input-success': formData.MrnStatus === 'SUCCESS',
                            'input-idle': ['ERROR', 'SUCCESS'].some(_item => _item != formData.MrnStatus) && (formData.MrnStatus === 'LOADING' || formData.Mrn),
                          }" 
                          :placeholder="formData.Patient.Mrn ? 'No.Rekam Medik baru': 'No Rekam Medik'" 
                          autocomplete="off"
                          v-model="Mrn"
                        >   
                        <VerificationAnimation v-if="formData.MrnStatus === 'LOADING'" class="verification-animation" />
                        <button @click="clearMrn" v-if="formData.MrnStatus !== 'LOADING'" class="btn-close-input" type="button">  
                          <CloseIcon/>
                        </button>  
                      </div>
                      <button v-if="formData.MrnStatus === 'ERROR' || (formData.Form === 'EDIT' && formData.MrnStatus === 'IDLE') || (formData.Form === 'EDIT' && formData.MrnStatus === 'LOADING') || (formData.Form === 'EDIT' && formData.MrnStatus === 'SUCCESS')" @click="buttonBatal" type="button" class="btn font-weight-bold" style="color: #5578EB; border-radius: 10px; font-size: 14px;">Batal</button>
                    </div>
                    <div v-if="formData.MrnStatus === 'ERROR' && formData.isShowInputError">
                      <p class="font-weight-normal mb-0" style="font-size: 12px; color: #C2283C;">
                        No. Rekam Medik sudah digunakan
                      </p>
                      <div class="lihat-detail-container mb-4">
                        <p @click="openInfoPatientModal" class="font-weight-bold mb-0" style="font-size: 12px; color: #C2283C; text-decoration: underline; cursor: pointer;">
                          Lihat Detail
                        </p>
                        <b-tooltip
                          target="tooltip-target"
                          placement="bottomright"
                          custom-class="custom-tooltip-modal special-arrow"
                        >
                          Silakan periksa kembali No. Medik yang Anda masukkan sebelum melanjutkan proses perubahan No. Rekam Medik
                        </b-tooltip>
                        <span id="tooltip-target" class="tooltip-icon">
                          <InfoIcon/>
                        </span>
                      </div>
                    </div>
                    <div v-else-if="formData.MrnStatus === 'ERROR' && formData.isShowInputError == false">
                      <p class="font-weight-normal mb-1 mt-1" style="font-size: 12px; color: #C2283C;">
                        Gagal menampilkan data
                      </p>
                      <p @click="refreshButton" class="font-weight-normal mb-2" style="font-size: 12px; color: #5578EB; text-decoration: underline; cursor: pointer;">
                        Refresh
                      </p>
                    </div>
                    <!-- Input field for name -->
                    <div v-if="formData.MrnStatus === 'ERROR' && formData.isShowInputError">
                      <div class="mt-4" style="padding-right: 8rem;">
                        <div class="input-wrapper">
                          <input 
                            type="text" 
                            class="form-control" 
                            :class="{
                              'input-error': formData.NamaStatus === 'ERROR', 
                              'input-success': formData.NamaStatus === 'SUCCESS',
                              'input-idle': ['ERROR', 'SUCCESS'].some(_item => _item != formData.NamaStatus) && (formData.NamaStatus === 'LOADING' || formData.Nama)
                            }"
                            id="name" 
                            placeholder="Nama"
                            autocomplete="off"
                            v-model="Nama"
                          >  
                          <VerificationAnimation v-if="formData.NamaStatus === 'LOADING'" class="verification-animation" />
                          <button @click="clearNama" v-if="formData.NamaStatus !== 'LOADING'" class="btn-close-input" type="button">  
                            <CloseIcon />
                          </button>     
                        </div>
                        <p v-if="formData.NamaStatus !== 'ERROR'" class="font-weight-normal mb-0" style="font-size: 12px; color: #636363;">
                          Isi nama pemilik No. Rekam Medik sebelumnya
                        </p>
                        <p v-else-if="formData.NamaStatus === 'ERROR'" class="font-weight-normal mb-0" style="font-size: 12px; color: #C2283C;">
                          Nama pemilik No. Rekam Medik sebelumnya salah
                        </p>
                      </div>
                      <!-- Input field for date -->
                      <div style="padding-right: 8rem;">
                        <div class="input-wrapper mt-4 field-date-picker">
                          <vue-date-picker
                            v-model="TanggalLahir"
                            :name="'tanggal_lahir'"
                            :inputClass="['form-control',{
                              'input-error': formData.TanggalLahirStatus === 'ERROR', 
                              'input-success': formData.TanggalLahirStatus === 'SUCCESS',
                              'input-idle': ['ERROR', 'SUCCESS'].some(_item => _item != formData.TanggalLahirStatus) && (formData.TanggalLahirStatus === 'LOADING' || formData.TanggalLahir)
                            }]"
                            :showDefaultIcon="false"
                            :clearable="false"
                          >
                          </vue-date-picker>
                          <VerificationAnimation v-if="formData.TanggalLahirStatus === 'LOADING'" class="verification-animation" />
                          <DateIcon v-else class="icon" />
                        </div>
                        <p v-if="formData.TanggalLahirStatus !== 'ERROR'" class="font-weight-normal mb-0" style="font-size: 12px; color: #636363;">
                          Isi tanggal lahir pemilik No. Rekam Medik sebelumnya
                        </p>
                        <p v-else-if="formData.TanggalLahirStatus === 'ERROR'" class="font-weight-normal mb-0" style="font-size: 12px; color: #C2283C;">
                          Tanggal lahir pemilik No. Rekam Medik sebelumnya salah
                        </p>
                      </div>
                    </div>
                  </div>    
                </div>
                <!-- Input field for nomor registrasi -->
                <div class="mb-3 row align-items-center">
                  <label for="nomorRegistrasi" class="col-sm-3 form-label  font-weight-bold" style="color: #636363; font-size: 16px;">Nomor Registrasi <span class="text-danger">*</span></label>
                  <div class="col-7">
                    <div class="input-wrapper">
                      <input 
                        type="text" 
                        class="form-control" 
                        :class="{
                            'input-idle': formData.NoRegis
                          }"
                        id="nomorRegistrasi" 
                        placeholder="Nomor registrasi"
                        autocomplete="off"
                        v-model="NoRegis"
                      >       
                      <button @click="clearNoRegis" class="btn-close-input" type="button">  
                        <CloseIcon/>
                      </button>  
                    </div> 
                  </div>
                </div>
              </form>
            </div>
            <!-- Footer Button -->
            <div class="modal-footer border-1">
              <button @click="buttonClose" type="button" class="btn font-weight-bold mr-3" data-bs-dismiss="modal" style="background: #FFFFFF; color: #5578EB; font-size: 14px;">Tutup</button>
              <button 
                type="button" 
                class="btn font-weight-bold ml-3" 
                :style="buttonStyle"
                :disabled="!isFormComplete"
                @click="buttonSave"
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import InfoIcon from '../../_icons/InfoIcons.vue'
import VerificationAnimation from '../../_icons/VerificationIcon.vue'
import DateIcon from '../../_icons/DateIcon.vue'
import CloseIcon from '../../_icons/CloseIcons.vue'
import VueDatePicker from '../../_general/VueDatePicker.vue'

export default {
  name: 'InputMrnModal',
  inject: ['formData','setMrn','clearMrn', 'setNama', 'clearNama','setTanggalLahir', 'setNoRegis', 'clearNoRegis','buttonEdit', 'buttonBatal', 'openInfoPatientModal', 'buttonClose', 'buttonSave', 'refreshButton'],
  // inject: ['formData'],
  components: {
      InfoIcon,
      VerificationAnimation,
      DateIcon,
      CloseIcon,
      VueDatePicker
  },
  computed: {
    selectedClass () {
      let classTanggalLahir = `form-control`
      if (this.formData.TanggalLahirStatus === 'ERROR') {
        classTanggalLahir += ' input-error'
      } else if (this.formData.TanggalLahirStatus === 'SUCCESS') {
        classTanggalLahir += ' input-success'
      } else if (this.formData.TanggalLahirStatus === 'LOADING') {
        classTanggalLahir += ' input-idle'
      }
      return classTanggalLahir
    },
      isFormComplete() {      
          return (this.formData.MrnStatus == 'SUCCESS' || this.formData.MrnStatus == 'INVISIBLE' || (
            this.formData.MrnStatus == 'ERROR' && this.formData.NamaStatus == 'SUCCESS' && this.formData.TanggalLahirStatus == 'SUCCESS'
          )) && this.formData.NoRegis
      },
      buttonStyle() {
          return {
              background: this.isFormComplete ? '#5578EB' : '#C1C1C1',
              color: '#fafafa',
              borderRadius: '10px',
              fontSize: '14px',
              width: '104px',
              height: '40px',
              cursor: this.isFormComplete ? 'pointer' : 'not-allowed'
          };
      },
      Mrn: {
        get() {
          return this.formData.Mrn
        },
        set(value) {
          this.setMrn(value)
        }
      },
      Nama: {
        get() {
          return this.formData.Nama
        }, 
        set(value) {
          this.setNama(value)
        }
      },
      TanggalLahir: {
        get() {
          return this.formData.TanggalLahir
        },
        set(value) {
          this.setTanggalLahir(value)
        }
      },
      NoRegis: {
        get() {
          return this.formData.NoRegis
        },
        set(value) {
          this.setNoRegis(value)
        }
      }
  },
}


</script>

<style scoped>

  .field-date-picker {
    font-weight: 600;
    font-size: 14px;
  }

  .form-control::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: #E4E4E4;
  }

  .modal-lg {
    max-width: 706px;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .field-date-picker .form-control{
    border-radius: 6px;
  }

  .form-control {
    border-radius: 6px;
  }

  .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 1.2rem;
      color: #999;
      cursor: pointer;
      padding: 0;
      line-height: 1;
      pointer-events: none;
  }

  .btn-close-input {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 1.2rem;
      color: #999;
      cursor: pointer;
      padding: 0;
      line-height: 1;
      display: none;
  }

  .verification-animation {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    animation: rotate 2s linear infinite;
  }
  @keyframes rotate {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
  }

  .form-label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-right: 10px;
  }

  .input-wrapper {
      position: relative;
      width: 237px;
      height: 40px;
  }

  .tooltip-icon {
    display: inline-flex;
    margin-left: 4px;
  }

  .lihat-detail-container {
    display: flex;
    align-items: center;
  }

  .input-wrapper:focus-within .btn-close-input {
    display: block;
  }

   .input-idle{
      border-color:#5578EB ;
      outline:none ;
      box-shadow: none ;
   }

  .input-idle:focus, .input-idle:active {
    border-color: #5578EB;
    outline: none;
    box-shadow: 0px 0px 0px 2px #d5ddf9 !important;
  }

  .input-error {
    border-color: #C2283C ;
    box-shadow: unset ;
    outline: none;
  }

  .input-error:focus, .input-error:active {
    border-color: #C2283C;
    box-shadow: 0px 0px 0px 2px #C2283C80 !important;
    outline: none;
  } 

  .input-success {
    border-color: #66BB6A;
    box-shadow: none;
    outline: none;
  }

  .input-success:focus, .input-success:active {
    border-color: #66BB6A ;
    box-shadow: 0px 0px 0px 2px #EBF6F4 !important;
    outline: none;
  }
  .field-date-picker ::v-deep .input-idle {
    border-color: 1px solid #5578EB;
    outline: none;
    box-shadow: 0px 0px 0px 2px #5578EB;
    font-weight: 600;
    font-size: 14px;
  }

  .field-date-picker ::v-deep .input-error {
    border-color: #C2283C;
    box-shadow: 0px 0px 0px 2px #C2283C80;
    outline: none;
    font-weight: 600;
    font-size: 14px;
  } 

  .field-date-picker ::v-deep .input-success {
    border-color: #66BB6A;
    box-shadow: 0px 0px 0px 2px #EBF6F4;
    outline: none;
    font-weight: 600;
    font-size: 14px;
  }

  .hidden {
      display: none
  }

  #rekamMedik {
      font-size: 14px; 
      font-weight: 600;
      color: #636363;
  }

  #name {
      font-size: 14px; 
      font-weight: 600;
      color: #636363;
  }

  #date {
      font-size: 14px; 
      font-weight: 600;
      color: #636363;
  }

  #nomorRegistrasi {
      font-size: 14px; 
      font-weight: 600;
      color: #636363;
  }

  /* .tooltip-inner {
  max-width: 231px;
  padding: 10px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  background-color: #5578EB !important; 
} */

</style>