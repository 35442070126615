<template>

    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 80 80" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_15118_29565)">
            <path
                d="M40.0001 2.96304C19.5451 2.96304 2.96304 19.5451 2.96304 40.0001C2.96304 60.4551 19.5451 77.0371 40.0001 77.0371C60.4551 77.0371 77.0371 60.4551 77.0371 40.0001C77.0371 19.5451 60.4551 2.96304 40.0001 2.96304Z"
                :stroke="strokeColor" 
                stroke-width="5.55555" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
            />
            <path 
                d="M40 25.1852V40" 
                :stroke="strokeColor" 
                stroke-width="11.1111" 
                stroke-linecap="round"
                stroke-linejoin="round" 
            />
            <path 
                d="M40 54.8145H39.962" 
                :stroke="strokeColor" 
                stroke-width="11.1111" 
                stroke-linecap="round"
                stroke-linejoin="round" 
            />
              </g>
              <defs>
                <clipPath id="clip0_15118_29565">
                  <rect width="80" height="80" fill="white" transform="matrix(-1 0 0 -1 80 80)" />
                </clipPath>
              </defs>
            </svg>

</template>

<script>

export default {
    props: {
        width : {
        type : Number,
        default: 80
        },
        height : {
            type: Number,
            default: 80
        },
        strokeColor : {
            type: String,
            default: "#C2283C"
        }
    }
}

</script>

