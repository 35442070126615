
<template>
    <!-- <div
        :id="modalName"
        class="modal fade"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >  -->
      <div class="modal show" tabindex="-1" style="display: block">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content" style="border-radius: 12px;">
            <div class="modal-header">
              <button @click="closeConfirmation" type="button" class="close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <div class="alert-icon mb-3">
                <ExclamationMark />
              </div>
              <p class="modal-title mb-3 font-weight-bold" style="padding-left: 4.5rem; padding-right: 4.5rem; font-size: 20px;">Apakah Anda yakin ingin menggunakan dan merubah data No. Rekam Medik berikut?</p>
              <div class="patient-header mb-4">
                <p class="font-weight-bold mb-2" style="font-size: 14px; line-height: 17.07px; color: #636363;">No. Rekam Medik:</p>
                <p class="font-weight-bold mb-0" style="color:#3D3D3D; font-size: 20px;">{{ formData.PatientFetch.Mrn }}</p>
              </div>
              <div class="row g-2">
                <!-- Patient Fetch -->
                <div class="col">
                  <p class="text-left font-weight-bold" style="font-weight: 700; font-size: 14px; color: #636363;">Data pasien lama</p>
                  <div class="patient-data">
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Nama
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formData.PatientFetch.Nama }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;"> 
                        <p>
                          Tanggal lahir
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formatDate (formData.PatientFetch.TanggalLahir) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Gender
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formData.PatientFetch.Gender }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          No. Telepon
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formData.PatientFetch.NoTelp }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Email
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
                        <p>
                         {{ formData.PatientFetch.Email }}
                        </p>
                      </div>
                    </div>    
                  </div>
                </div>
                <div class="col-auto px-0 d-flex align-items-center justify-content-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 4.5L21 12M21 12L13.5 19.5M21 12H3" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            
              </div>
              <!-- Patient  -->
                <div class="col">
                    <p class="text-left font-weight-bold" style="font-weight: 700; font-size: 14px; color: #636363;">Data pasien baru</p>
                  <div class="patient-data">
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Nama
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formData.Patient.Nama }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Tanggal lahir
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formatDate(formData.Patient.TanggalLahir) }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Gender
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formData.Patient.Gender }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          No. Telepon
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
                        <p>
                          {{ formData.Patient.NoTelp }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 text-left" style="color: #23415B; font-weight: 400; font-size: 12px;">
                        <p>
                          Email
                        </p>
                      </div>
                      <div class="col text-left font-weight-bold text-break" style="color: #23415B; font-size: 12px;">
                        <p>
                         {{ formData.Patient.Email }}
                        </p>
                      </div>
                    </div>    
                  </div>
                </div>
              </div>
              <p v-if="formData.Form ==='EDIT'" class="mt-3" style="color: #C2283C; font-weight: 400; font-size: 14px;">No. Rekam Medik <span class="font-weight-bold">{{ formData.Patient.Mrn }}</span> akan digantikan dengan <span class="font-weight-bold">{{ formData.PatientFetch.Mrn }}</span>. Data pasien lama No. Rekam Medik diatas akan digantikan dengan data pasien baru</p>
              <p v-else class="mt-3" style="color: #C2283C; font-weight: 400; font-size: 14px;">Data pasien dengan No. Rekam Medik di atas akan digantikan dengan data pasien baru</p>
              <div class="my-3">
                <button type="button" class="btn me-4 font-weight-bold mr-3" @click="closeConfirmation" style="font-size: 14px; background: #FFFFFF; border: 1px solid #5578EB; color: #5578EB; width: 161px; border-radius: 10px;">Tidak, batalkan</button>
                <button type="button" class="btn font-weight-bold ml-3" @click="saveConfirmation" style="font-size: 14px; background: #5578EB; color: #fafafa; border-radius: 10px; width: 122px;">Ya, proses</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    
    <script>
      import ExclamationMark from  '../../_icons/ExclamationMark.vue';
      import CloseIcon from '../../_icons/CloseIcons.vue'
  
      export default {
      components: {
        ExclamationMark,
        CloseIcon
      },
      inject: ['formData', 'saveConfirmation', 'closeConfirmation'],
      // inject: ['formData'],
      methods: {
        formatDate(dateString) {
          const date = new Date(dateString);
          return date.toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'});
        },
        // saveConfirmation() {
        //   // this.buttonSave
        //   // function for button ya, proses
        // },
        // closeConfirmation() {
        //   // this.buttonSave
        //   // function for tidak, batalkan
        // }
      },
    }
    </script>
  
    <style scoped>
    
    .modal-content {
          border-radius: 10px;
          width: 784px;
        }
    
        .modal-header {
          border-bottom: none;
          padding-bottom: 0;
        }
    
        .modal-body {
          padding-top: 0;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
        }
    
        .patient-data {
          background-color: #fafafa;
          border-radius: 6px;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #C1C1C1
        }
    
        .patient-header {
          background-color: #f8f9fa;
          border-radius: 6px;
          padding: 10px;
          margin-bottom: 10px;
          border: 1px solid #D58B95;
          background: #FFF2F2;
    
        }
        .arrow-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            color: #6c757d;
        }
    </style>
    