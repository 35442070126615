<template>

    <svg 
        :width="16" 
        :height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_310_800)">
            <path
                d="M11 6.5C11 6.36739 11.0527 6.24021 11.1464 6.14645C11.2402 6.05268 11.3674 6 11.5 6H12.5C12.6326 6 12.7598 6.05268 12.8536 6.14645C12.9473 6.24021 13 6.36739 13 6.5V7.5C13 7.63261 12.9473 7.75979 12.8536 7.85355C12.7598 7.94732 12.6326 8 12.5 8H11.5C11.3674 8 11.2402 7.94732 11.1464 7.85355C11.0527 7.75979 11 7.63261 11 7.5V6.5ZM8 6.5C8 6.36739 8.05268 6.24021 8.14645 6.14645C8.24021 6.05268 8.36739 6 8.5 6H9.5C9.63261 6 9.75979 6.05268 9.85355 6.14645C9.94732 6.24021 10 6.36739 10 6.5V7.5C10 7.63261 9.94732 7.75979 9.85355 7.85355C9.75979 7.94732 9.63261 8 9.5 8H8.5C8.36739 8 8.24021 7.94732 8.14645 7.85355C8.05268 7.75979 8 7.63261 8 7.5V6.5ZM3 9.5C3 9.36739 3.05268 9.24021 3.14645 9.14645C3.24021 9.05268 3.36739 9 3.5 9H4.5C4.63261 9 4.75979 9.05268 4.85355 9.14645C4.94732 9.24021 5 9.36739 5 9.5V10.5C5 10.6326 4.94732 10.7598 4.85355 10.8536C4.75979 10.9473 4.63261 11 4.5 11H3.5C3.36739 11 3.24021 10.9473 3.14645 10.8536C3.05268 10.7598 3 10.6326 3 10.5V9.5ZM6 9.5C6 9.36739 6.05268 9.24021 6.14645 9.14645C6.24021 9.05268 6.36739 9 6.5 9H7.5C7.63261 9 7.75979 9.05268 7.85355 9.14645C7.94732 9.24021 8 9.36739 8 9.5V10.5C8 10.6326 7.94732 10.7598 7.85355 10.8536C7.75979 10.9473 7.63261 11 7.5 11H6.5C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5V9.5Z"
                :fill="strokeColor" 
            />
            <path
                d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0ZM1 4V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V4H1Z"
                :fill="strokeColor" 
            />
        </g>
        <defs>
            <clipPath id="clip0_310_800">
                <rect 
                    width="16" 
                    height="16" 
                    fill="white" 
                />
            </clipPath>
        </defs>
    </svg>

</template>

<script>

export default {
    props: {
        width : {
        type : Number,
        default: 16
        },
        height : {
            type: Number,
            default: 16
        },
        strokeColor : {
            type: String,
            default: "#C1C1C1"
        }
    }
}

</script>

