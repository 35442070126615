var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded h-100 pointer slot-jadwal with-shadow",class:[_vm.classBackground, { active: _vm.isActive }],style:({ borderLeft: `5px solid ${_vm.data.hexColor}`}),attrs:{"id":_vm.id},on:{"click":_vm.eventClick}},[(_vm.data.type === _vm.SlotJadwalType.HOLIDAY)?[_c('div',[_c('label',{staticClass:"position-slot-holiday"},[_vm._v("Libur "),_c('br'),_vm._v(" "+_vm._s(_vm.data.holidayRemarks)+" ")])])]:_vm._e(),(_vm.data.type == _vm.SlotJadwalType.WAITING)?[(_vm.jumlahWaiting > 0)?_c('div',{staticClass:"text-left p-2 waiting-list"},[_vm._v(" ( "+_vm._s(_vm.data.data[0].jadwal.jamMulai)+" - "+_vm._s(_vm.data.data[0].jadwal.jamSelesai)+" ) Terdapat "),_c('strong',[_vm._v(_vm._s(_vm.jumlahWaiting))]),_vm._v(" waiting list ")]):_vm._e()]:[(_vm.data.type != _vm.SlotJadwalType.HOLIDAY)?_c('div',{staticClass:"box-content-slot-jadwal",class:_vm.data.type == _vm.SlotJadwalType.RESERVED ? 'grid-slot-3': 'grid-slot-2'},[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.start))+" - "+_vm._s(_vm._f("formatTime")(_vm.end))+" "),(_vm.data.reservasi && _vm.data.reservasi.jamMulaiAppointment && _vm.data.reservasi.jamMulai != _vm.data.reservasi.jamMulaiAppointment)?_c('span',[_vm._v(" (appt. "+_vm._s(_vm.data.reservasi.jamMulaiAppointment)+" - "+_vm._s(_vm.data.reservasi.jamSelesaiAppointment)+") ")]):_vm._e(),(
                        _vm.data.type === _vm.SlotJadwalType.ANTRIAN
                    )?_c('div',[_vm._v(" WALK-IN ")]):(
                        _vm.data.type === _vm.SlotJadwalType.LUNCHBREAK ||
                        _vm.data.type === _vm.SlotJadwalType.OTHER_EVENT ||
                        _vm.data.type === _vm.SlotJadwalType.CLOSE_REGISTRATION ||
                        _vm.data.type === _vm.SlotJadwalType.IN_OPERATION
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.data.type)+" ")]):(
                        _vm.data.type !== _vm.SlotJadwalType.EMPTY &&
                            _vm.data.type !== _vm.SlotJadwalType.TELAT &&
                            _vm.data.type !== _vm.SlotJadwalType.INTERNAL_EVENT &&
                            _vm.data.type !== _vm.SlotJadwalType.BLOCKED
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.data.type)+" by "+_vm._s(_vm.data.reservasi ? _vm.data.reservasi.namaPasien : '-')+" ")]):(
                        _vm.data.type !== _vm.SlotJadwalType.EMPTY &&
                            _vm.data.type !== _vm.SlotJadwalType.RESERVED
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.data.type)+" "),(_vm.data.reservasi && _vm.data.reservasi.remark)?_c('p',[_vm._v(" "+_vm._s(_vm.data.reservasi.remark)+" ")]):_vm._e()]):_vm._e(),(_vm.data.reservasi && _vm.data.reservasi.pesan)?_c('div',[_vm._v(" "+_vm._s(_vm.data.reservasi.pesan)+" ")]):_vm._e()]),(
                    _vm.xVue.state.DoctorAppointmentStore.isPermissionWhatsapp
                )?[(_vm.data.reservasi && _vm.data.reservasi.telp)?_c('div',{staticClass:"text-center"},[_vm._v(" Telp: "),_c('span',{staticClass:"show-bigger",on:{"click":function($event){$event.stopPropagation();return _vm.WAme(_vm.data.reservasi.telp, _vm.data.reservasi.generatedId)}}},[_vm._v(_vm._s(_vm.data.reservasi.telp)+" ")])]):_vm._e()]:[(_vm.data.reservasi && _vm.data.reservasi.telp)?_c('div',{staticClass:"text-center"},[_vm._v(" Telp: "),_c('span',[_vm._v(_vm._s(_vm.data.reservasi.telp))])]):_vm._e()],(_vm.data.type == _vm.SlotJadwalType.EMPTY)?_c('div',{staticClass:"text-right"},[_c('i',{staticClass:"fa fa-plus pointer"})]):(_vm.data.type == _vm.SlotJadwalType.BLOCKED)?_c('div',{staticClass:"text-right"},[_c('i',{staticClass:"fas fa-ban"})]):(
                    _vm.data.reservasi && _vm.data.reservasi.createdVia == 'PUBLIC'
                )?_c('div',{staticClass:"text-right"},[_c('i',{staticClass:"fas fa-mobile-alt"})]):(_vm.data.reservasi && _vm.data.reservasi.telekonsul)?_c('div',{staticClass:"text-right"},[_c('i',{staticClass:"fas fa-headset pointer"})]):_vm._e()],2):_vm._e()],(
            _vm.data.reservasi &&
                _vm.data.reservasi.telp &&
                _vm.xVue.state.DoctorAppointmentStore.isPermissionWhatsapp
        )?_c('b-tooltip',{attrs:{"target":`slot-${_vm.data.reservasi.id}`,"triggers":"hover"}},[_c('div',{staticClass:"row text-left"},[_c('div',{staticClass:"col-12"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.start))+" - "+_vm._s(_vm._f("formatTime")(_vm.end))+" ")]),(
                    _vm.data.type !== _vm.SlotJadwalType.EMPTY &&
                        _vm.data.type !== _vm.SlotJadwalType.TELAT &&
                        _vm.data.type !== _vm.SlotJadwalType.INTERNAL_EVENT &&
                        _vm.data.type !== _vm.SlotJadwalType.BLOCKED
                )?_c('div',{staticClass:"col-12"},[_vm._v(" "+_vm._s(_vm.data.type)+" by "+_vm._s(_vm.data.reservasi ? _vm.data.reservasi.namaPasien : '-')+" ")]):(
                    _vm.data.type !== _vm.SlotJadwalType.EMPTY &&
                        _vm.data.type !== _vm.SlotJadwalType.RESERVED
                )?_c('div',{staticClass:"col-12"},[_vm._v(" "+_vm._s(_vm.data.type)+" "),(_vm.data.reservasi && _vm.data.reservasi.remark)?_c('p',[_vm._v(" "+_vm._s(_vm.data.reservasi.remark)+" ")]):_vm._e()]):_vm._e(),(_vm.data.reservasi && _vm.data.reservasi.pesan)?_c('div',{staticClass:"d-block text-left col-12 my-2"},[_vm._v(" "+_vm._s(_vm.data.reservasi.pesan)+" ")]):_vm._e(),(_vm.data.reservasi && _vm.data.reservasi.telp)?_c('div',{staticClass:"d-block pointer col-12"},[_c('span',{staticClass:"show-bigger",on:{"click":function($event){$event.stopPropagation();return _vm.WAme(_vm.data.reservasi.telp, _vm.data.reservasi.generatedId)}}},[_vm._v(_vm._s(_vm.data.reservasi.telp)+" ")])]):_vm._e()])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }