<template>

    <svg 
        :width="width" 
        :height="height" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M1.5 8C1.5 4.41 4.41 1.5 8 1.5C11.59 1.5 14.5 4.41 14.5 8C14.5 11.59 11.59 14.5 8 14.5C4.41 14.5 1.5 11.59 1.5 8ZM7.304 7.03867C8.068 6.65667 8.92867 7.34733 8.72133 8.176L8.24867 10.0667L8.27667 10.0533C8.39414 10.0017 8.52698 9.99719 8.64768 10.0408C8.76839 10.0843 8.86771 10.1727 8.9251 10.2874C8.98249 10.4022 8.99355 10.5347 8.956 10.6574C8.91844 10.7801 8.83514 10.8837 8.72333 10.9467L8.69667 10.9613C7.932 11.3433 7.07133 10.6527 7.27867 9.824L7.752 7.93333L7.724 7.94667C7.66503 7.97944 7.60003 7.99994 7.53292 8.00693C7.46581 8.01392 7.39798 8.00726 7.33352 7.98734C7.26905 7.96743 7.20929 7.93467 7.15782 7.89105C7.10635 7.84742 7.06424 7.79383 7.03403 7.7335C7.00382 7.67317 6.98613 7.60735 6.98204 7.54C6.97794 7.47266 6.98751 7.40518 7.01018 7.34163C7.03285 7.27808 7.06815 7.21978 7.11394 7.17023C7.15974 7.12069 7.21509 7.08092 7.27667 7.05333L7.304 7.03867ZM8 6C8.13261 6 8.25979 5.94732 8.35355 5.85355C8.44732 5.75979 8.5 5.63261 8.5 5.5C8.5 5.36739 8.44732 5.24021 8.35355 5.14645C8.25979 5.05268 8.13261 5 8 5C7.86739 5 7.74022 5.05268 7.64645 5.14645C7.55268 5.24021 7.5 5.36739 7.5 5.5C7.5 5.63261 7.55268 5.75979 7.64645 5.85355C7.74022 5.94732 7.86739 6 8 6Z" 
            :fill="strokeColor"
        />
    </svg>

</template>

<script>

export default {
    props : {
        width : {
        type : Number,
        default: 16
        },
        height : {
            type: Number,
            default: 16
        },
        strokeColor : {
            type: String,
            default: "#929292"
        }
    }
}

</script>